import { useEffect } from 'react';
import { useKlarnaMessageContext } from '../context/KlarnaMessageContext';
import { cookieManager } from '@vaimo-int/one-trust';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare module globalThis {
    let document: any;
}

export const useKlarnaMessageScript = (): void => {
    const { isKlarnaPaymentMessageEnabled, klarnaMessageClientId, setIsScriptLoaded } = useKlarnaMessageContext();

    const onScriptLoad = () => {
        setIsScriptLoaded(true);
    };

    useEffect(() => {
        if (!isKlarnaPaymentMessageEnabled || !klarnaMessageClientId) return;

        const KLARNA_MESSAGE_PATH = `https://js.klarna.com/web-sdk/v1/klarna.js`;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = KLARNA_MESSAGE_PATH;
        script.setAttribute('data-client-id', klarnaMessageClientId);

        if (process.env.NODE_ENV === 'production') {
            script.setAttribute('data-environment', 'production');
        } else {
            script.setAttribute('data-environment', 'playground');
        }

        cookieManager.executeIfAllowed(() => {
            globalThis.document.body.appendChild(script);
        }, cookieManager.PrivacyGroupEnum.FUNCTIONAL);

        script.addEventListener('load', onScriptLoad);

        return () => {
            script.removeEventListener('load', onScriptLoad);
            if (globalThis.document.body.contains(script)) {
                globalThis.document.body.removeChild(script);
                setIsScriptLoaded(false);
            }
        };
    }, [isKlarnaPaymentMessageEnabled, klarnaMessageClientId]);
};
